<template>
  <a-form :form="form" @submit.prevent.native="onSubmit">
    <div class="card mb-3">
      <div class="card-header">
        <strong>Password</strong>
      </div>
      <div class="card-body">
        <p>Your new password should have at least 6 characters. It will be changed instantly, and encrypted and stored securely in our database.</p>
        <div class="row mt-4">
          <div class="col-12 col-md-12 col-lg-4">
            <a-form-item label="Current Password" :colon="false" required>
              <a-input
                v-decorator="['password_old', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Password is required.' }],
                  }, {
                    trigger: 'blur',
                    rules: [{ min: 6, message: 'Password is too short.' }],
                  }]
                }]"
                name="password_old"
                type="password"
              />
            </a-form-item>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <a-form-item label="New Password" :colon="false" required>
              <a-input
                v-decorator="['password_new', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Password is required.' }],
                  }, {
                    trigger: 'blur',
                    rules: [{ min: 6, message: 'Password is too short.' }],
                  }]
                }]"
                name="password_new"
                type="password"
                placeholder="Choose a secure password"
              />
            </a-form-item>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <a-form-item label="Confirm New Password" :colon="false" required>
              <a-input
                v-decorator="['password_new_confirm', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Password is required.' }],
                  }, {
                    trigger: 'blur',
                    rules: [{ min: 6, message: 'Password is too short.' }],
                  }]
                }]"
                name="password_new_confirm"
                type="password"
                placeholder="Enter same password once more"
              />
            </a-form-item>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <a-button type="primary" html-type="submit" :loading="loading">
          Save
        </a-button>
      </div>
    </div>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    }
  },

  created() {
    this.form = this.$form.createForm(this)
  },

  methods: {
    onSubmit() {
      this.form.validateFields(async (err, values) => {
        try {
          if (err) return false
          this.loading = true

          await this.$httpd.put(`/user/password`, values)
        } catch (err) {
          if (err.response) {
            this.$message.error(err.response.data.message)
          } else {
            this.$message.error(err.message)
          }

          return false
        } finally {
          this.loading = false
        }

        this.form.resetFields()
        this.$message.success('Password has been updated.')
      })
    },
  }
}
</script>
